import { Page } from "ui_components"
import dayjs from "dayjs"
import { useState } from "react"
import { useRouter } from "next/router"
import { prisma } from "lib/prisma"
import localizedFormat from "dayjs/plugin/localizedFormat"
import { BookingMain } from "ui_components/Bookings/BookingsPage/BookingsMain"

dayjs.extend(localizedFormat)

export default function BookingPage({ metadata }: any) {
  const router = useRouter()
  const isHomePage = router.query?.pageId?.length === 1
  const initialEventType = isHomePage ? null : router.query?.pageId[1]
  const [selectedEventTypeId, setSelectedEventTypeId] = useState<string | null>(
    initialEventType
  )
  const slug = router.query?.pageId[0] ?? ""
  return (
    <Page
      title={metadata.title}
      description={metadata.description}
      isBookingPage={true}
    >
      <div className="min-h-screen bg-white">
        <BookingMain
          isHomePage={isHomePage}
          selectedEventTypeId={selectedEventTypeId}
          setSelectedEventTypeId={(id) => {
            setSelectedEventTypeId(id)

            if (id != null) {
              router.push(`${router.asPath}/${id}`)
            } else {
              router.push(`/${slug}`)
            }
          }}
          slug={slug}
        ></BookingMain>
      </div>
    </Page>
  )
}

export async function getStaticPaths() {
  const sites = await prisma.bookingSite.findMany()

  const bookingSlugs = sites.map((site) => site.slug)

  return {
    paths: bookingSlugs.map((slug) => ({
      params: { pageId: typeof slug === "string" ? [slug] : slug },
    })),
    fallback: "blocking",
  }
}

export async function getStaticProps({ params }: any) {
  const firstParam = params.pageId[0]

  // This could be problems when building due to manu connections to the database
  // It should probably be an endpoint
  const bookingSite = await prisma.bookingSite.findUnique({
    where: {
      slug: firstParam,
    },
  })

  if (bookingSite == null) {
    return {
      notFound: true,
    }
  }

  return {
    props: {
      metadata: {
        title: bookingSite.title,
        description: bookingSite.description,
        isBookingSite: true,
      },
    },
  }
}
